/**
 * Manager
 * @class Page Manager
 * @returns
 */
var Manager = Klass.create();

Manager.extend(JqManager);

//definition start
Manager.extend({
/**
 * init
 */
init:function(){
	this.timer = null;
	this.perf_no = 1;
	this.app_no = 1;
	this.anim = false;
	this.$document = $(document);
	this.$window = $(window);
},
/**
 * 設定
 */
set:function(){
	this.setParam();
	this.setObject();
},
/**
 * 開始
 */
start:function(){
	console.log("start");
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this, this.setState, [],"");
	oCom.call(this, this.setEvent, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * 開始後
 */
after:function(){
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	//oCom.call(this, this.animAll, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
///////////////////////////////////////////////////////////////////////////////////
//setting
///////////////////////////////////////////////////////////////////////////////////
/**
 * パラメータセット
 */
setParam:function(){
	var oSelf = this;

	// IEの設定を生かす場合コメントアウトをとる
	// if(_cmn["ua"] === "ie" && ~~_cmn["ver"] === 8){
	// 	this.ie8 = true;
	// }
	// if(_cmn["ua"] !== "ie" || (_cmn["ua"] === "ie" && ~~_cmn["ver"] >= 10)){
	// 	this.modern = true;
	// }
},

/**
 * オブジェクト設定
 */
setObject:function(){
},
/**
 * 初期状態セット
 */
setState:function(){
	console.log("setState");
	if(!_manager.ie8){
		if(_manager.modern){
			//modern
		}else{
			//ie9
		}
	}else{
		//ie8
	}
	$("body").removeClass("hide");
	this.resize(null,false);
},
/**
 * setEvent
 */
setEvent:function(){
	var oSelf = this;

	//cmn
	this.setWayPoint();
	this.setResize();
},
/**
 * スクロール位置ベースのイベント
 */
setWayPoint:function(){
	//top
//	$("#top").waypoint(function(direction){
//		_cmn.header.changeMode("white");
//	},{offset:300});
//	$("#top").waypoint(function(direction){
//		_cmn.header.changeMode("white");
//	},{offset:0});
},
/**
 * リサイズイベントセット
 */
setResize:function(){
	$(window).bind("resize",function(e){_manager.resize(e,true);});
},
menuToggle:function(e){
	console.log(e);
	var oSelf = this;

	if(oSelf.anim){
		return;
	}
	oSelf.anim = true;
	
	if(oSelf.menuIsClose()){
		// 開く
		oSelf.$menu.removeClass("close");
		oSelf.$menu.removeClass("hidden");
	}else{
		// 閉じる
		oSelf.$menu.addClass("close");
		oSelf.$animOverLay.addClass("hidden");
	}
},
menuViewToggle:function(e){
	var oSelf = this;
	if(oSelf.menuIsClose()){
		oSelf.$menu.addClass("hidden");
	}
},
menuIsClose:function(){
	return this.$menu.hasClass("close");
},
///////////////////////////////////////////////////////////////////////////////////
//event
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//function
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//util
///////////////////////////////////////////////////////////////////////////////////
/**
 * resize
 */
resize:function(e,b){
	var $window = $(window);
	var iWindowW = window.innerWidth;
	var iWindowH = window.innerHeight;
}
});
//definition end